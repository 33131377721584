<template>
  <div class="login-form">
    <div class="card">
      <div class="card-body">
        <!-- Credit Card -->
        <div id="pay-invoice">
          <div class="card-body">
            <div class="card-title">
              <h3 class="text-center">Login</h3>
            </div>
            <hr>
            <form @submit.prevent="login">
              <div class="form-group">
                <label for="cc-payment" class="control-label mb-1">Correo electrónico</label>
                <input
                v-model="user.email"
                placeholder="usuario@ineb.com"
                type="email"
                class="form-control"
                aria-required="true"
                aria-invalid="false">
                <span class="alert alert-danger" v-if="errors.email">{{errors.email}}</span>
              </div>
              <div class="form-group has-success">
                <label for="cc-name" class="control-label mb-1">Clave</label>
                <input
                  v-model="user.password"
                  type="password"
                  class="form-control">
                  <span class="alert alert-danger" v-if="errors.password">{{errors.password}}</span>
              </div>
              <div>
                <button disabled
                  v-if="loadding > 100 && loadding > 0"
                  id="payment-button"
                  class="btn btn-lg btn-info btn-block">
                  Enviando: {{loadding}} %
                </button>
                <button v-else id="payment-button"
                type="submit" class="btn btn-lg btn-info btn-block">
                  <span id="payment-button-amount">Ingresar </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> <!-- .card -->
  </div><!--/.col-->
</template>
<script>
/* eslint-disable */
export default {
  created() {
    if (!window.localStorage.getItem('_token')) {
      this.$store.commit('SET_LAYOUT', 'login');
    }else{
      this.$store.commit('SET_LAYOUT', 'default');
    }
  },
  name: 'Login',
  data() {
    return {
      sending: false,
      errors: [],
      user: [],
      url: process.env.VUE_APP_BASE_URL + '/api-token-auth/'
    };
  },
  methods: {
    login() {
      this.errors = [];
      if (!this.user.email) {
        this.errors.email = 'El correo es obligatorio.';
        return false;
      }
      if (!this.user.password) {
        this.errors.password = 'La clave es obligaroira.';
        return false;
      }
      const payload = {};
      payload.email = this.user.email;
      payload.password = this.user.password;
      const self = this;
      let usercustom = '';
      this.sendRequest(payload).then((data) => {
        usercustom = self.parseJwt(data.data.token);
        usercustom.token = data.data.token;
        self.save_storage('user', usercustom);        
        self.$store.dispatch('login', { user: usercustom });                
        self.save_storage('_token', true);
        self.$swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Acceso exitos',
          showConfirmButton: false,
          timer: 1500,
        });
        self.redirect()
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible autenticar.', 'error');
      });
      return null;
    },
    unixTimetoDate(time) {
      return new Date(time * 1000).toISOString().slice(0, 19).replace('T', ' ');
    },
    parseJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const dataJWT = JSON.parse(window.atob(base64));
        return dataJWT;
      } catch (err) {
        return null;
      }
    },
    redirect(_user) {
      this.$router.push({name: 'Home'})
    },
    async sendRequest(payload) {
      const result = await this.$http.post(this.url, payload);
      return result;
    },
    async getRequest(payload, url) {
      const result = await this.$http.get(url, { params: payload });
      return result;
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage() {
      if (JSON.parse(localStorage.getItem('user'))) {
        return localStorage.getItem('user');
      }
      return null;
    },
  },
  computed: {
    loadding() {
      return this.$store.state.progress;
    },
  },
};

</script>
